import { EErrorType, errorHandler } from '~/utils/errorHandlers/customErrorHandler';
import type { IUseRenderAsyncOrganisms } from '~/composables/render/asyncOrganisms/useRenderAsyncOrganisms.types';
import type {
  IGenericComponentAsync,
  IGenericComponent,
} from '#build/types/ice-core-generic-request';
import { useIceCoreGenericRequest } from '#imports';

/**
 * @description Composable функция для работы с асинхронными организмами.
 * @returns {IUseRenderAsyncOrganisms}
 */
export default function useRenderAsyncOrganisms(): IUseRenderAsyncOrganisms {
  const { getGenericGridData } = useIceCoreGenericRequest();

  async function getPageAsyncComponents(): Promise<IGenericComponentAsync[] | null> {
    if (!getGenericGridData.value) return null;
    if (!Array.isArray(getGenericGridData.value)) {
      return errorHandler.throwError(
        EErrorType.NUXT_SERVER_ERROR,
        'Grid is not valid.',
        `
      Структура данных: ${JSON.stringify(getGenericGridData)}
      \n
      \n
      Структура объекта сетки: ${JSON.stringify(getGenericGridData.value)}
    `,
      );
    }

    return await Promise.all(
      getGenericGridData.value.map(async (item: IGenericComponent<unknown>) => {
        return {
          data: item,
          component: defineAsyncComponent(
            async () =>
              await import(
                `~/components/O/${item.attributes['component-group']}/${item.attributes['component-modifier']}/${item.attributes['component-view']}.vue`
              ),
          ),
        };
      }),
    );
  }

  return {
    getPageAsyncComponents,
  };
}
